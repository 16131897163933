import React, {
  useState,
  useEffect,
} from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { spacePad, zeroPad } from "../../services/common/utils";
import { Route, Routes, useNavigate } from "react-router-dom";
import MaterialTable, { Column } from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import { useAuth } from "../../services/common/utils";
import {
  getAllOrderStatus,
  getOrdersByStatus,
} from "../../services/data-service/order-service";
import { toast } from "react-toastify";
import ContentHeader from "../../components/content-header/ContentHeader";

export default function Orders(props) {
  const navigate = useNavigate();
  const { userid, user, role, refid } = useAuth();  
  const [filterType, setFilterType] = useState("NEW ORDER");
  const [orderData, setOrderData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [statusOption, setStatusOption] = useState([]);

  const columns = [
    { title: "#", render: (rowData) => rowData.tableData.id + 1, width: 80 },
    {
      title: "Order Id",
      field: "orderId",

      render: (rowData) => {
        return (
          <Link
            to={`/order-detail/${rowData.orderId}`}            
            className="nav-link"
          >
            {"ORD-" + zeroPad(rowData.orderId, 5)}
          </Link>
        );
      },
    },
    { title: "Registration No", field: "regNo" },
    { title: "Chassis No", field: "chassisNo" },
    { title: "Engine No", field: "engineNo" },
    { title: "Vehicle Class", field: "vehicle_class" },
    { title: "Vehicle Type", field: "vehicle_type" },
    { title: "Front Laser Code", field: "f_laser_code" },
    { title: "Rear Laser Code", field: "r_laser_code" },
    { title: "Order Type", field: "orderType" },
    { title: "Registration Date", field: "reg_date" },
    { title: "Vehicle Model", field: "vehicle_model" },
    { title: "Dealer Name", field: "dealerName" },
    { title: "Oem Name", field: "oemName" },
    {
      title: "HSRP",
      field: "vahan_status",
      render: (rowData) => {
        if (rowData.vahan_status == "Fitted")
          return <span className="badge bg-success">{rowData.vahan_status}</span>;
        else {
          return <span className="badge bg-danger">{rowData.vahan_status}</span>;
        }
      },
    },
    { title: "Order Status", field: "orderStatus" },
    { title: "Created", field: "created_at" },
  ];

  useEffect(() => {
    let optionData = [];
    getAllOrderStatus()
      .then((response) => {        
        response.data.map((item) =>
          optionData.push({
            value: item.status,
            label: spacePad(item.status, 10),
          })
        );
      })
      .catch((error) => {
        toast.error("error", "Something went wrong, try again later!!");
      });
    if (role === "admin") {
      optionData.push({ value: "all", label: spacePad("ALL", 10) });
    }
    else if (role === "dealer"){
      optionData.push({ value: "all", label: spacePad("ALL", 10) });
    }

    setStatusOption(optionData);
  }, []);

  useEffect(() => {
    getOrdersByStatus(filterType).then(
      (response) => {
        if (response.status === 200) {
          setOrderData(response.data);
        }
        // this.setState({ orderData: response.data, orderDataLoading: false });
        setIsLoading(false);
        // autoSizeAll(false);
      },
      (error) => {
        console.log(error);
      }
    );
  }, [filterType]);

  function changeTableType(type) {
    setIsLoading(true);
    setFilterType(type);
  }
  return (
    <>
      <ContentHeader title="Orders" />
      <section className="content">
        <div className="container-fluid">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">
                {role === "admin" ? "All Orders" : "Your Orders"}
              </h3>
              <div className="card-tools col-6 col-md-3">
                <Select
                  options={statusOption}
                  onChange={(e) => changeTableType(e.value)}
                  styles={{ width: "max-content" }}
                  defaultValue={{ label: "NEW ORDER", value: "NEW ORDER" }}
                />
              </div>
            </div>
            <MaterialTable
              columns={columns}
              data={orderData}
              isLoading={isLoading}
              title=""
              options={{
                headerStyle: { fontWeight: "bold" },
                pageSizeOptions: [5, 20, 50, 100],
                exportAllData: true,
                exportMenu: [
                  {
                    label: "Export CSV",
                    exportFunc: (cols, datas) =>
                      ExportCsv(cols, datas, "Orders"),
                  },
                ],
              }}
            />
          </div>
        </div>
        {/* /.container-fluid */}
      </section>
    </>
  );
}

// export const API_URL = "http://localhost:5000";

// Prod
// export const API_URL = "https://dakpq46dbi.execute-api.ap-south-1.amazonaws.com/prod";
export const API_URL = process.env.REACT_APP_PROD_API_URL
export const RAZOR_KEY = process.env.REACT_APP_PROD_RAZOR_KEY

// Dev
// export const API_URL = "https://svu3grwyrl.execute-api.ap-south-1.amazonaws.com/dev";
// export const API_URL = process.env.REACT_APP_DEV_API_URL
// export const RAZOR_KEY = process.env.REACT_APP_DEV_RAZOR_KEY

// export const API_URL = "https://api-portal.hsrp.fit";
// export const VAHAN_API_URL = "https://208.109.14.243:8800";
